export const GET_RELEASES_REQUEST = 'GET_RELEASES_REQUEST';
export const GET_RELEASES_SUCCESS = 'GET_RELEASES_SUCCESS';
export const GET_RELEASES_FAILED = 'GET_RELEASES_FAILED';

export const GET_RELEASE_DETAIL_REQUEST = 'GET_RELEASE_DETAIL_REQUEST';
export const GET_RELEASE_DETAIL_SUCCESS = 'GET_RELEASE_DETAIL_SUCCESS';
export const GET_RELEASE_DETAIL_FAILED = 'GET_RELEASE_DETAIL_FAILED';

export const CREATE_RELEASE_REQUEST = 'CREATE_RELEASE_REQUEST';
export const CREATE_RELEASE_SUCCESS = 'CREATE_RELEASE_SUCCESS';
export const CREATE_RELEASE_FAILED = 'CREATE_RELEASE_FAILED';

export const DELETE_RELEASE_REQUEST = 'DELETE_RELEASE_REQUEST';
export const DELETE_RELEASE_SUCCESS = 'DELETE_RELEASE_SUCCESS';
export const DELETE_RELEASE_FAILED = 'DELETE_RELEASE_FAILED';

export const RESET_RELEASE = 'RESET_RELEASE';
