import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
// import LogoIconImg from "../../assets/images/logo-icon.png"
// import LogoLightIconImg from "../../assets/images/logo-light-icon.png"
// import LogoTextImg from "../../assets/images/logo-text.png"
import LogoLightMobile from "../../assets/images/logo-mobile.svg"

// const LogoIconImg = "https://yep2022.dev-enjoyworks.com/year_party_file/images/icons/logo_mobile.svg"
// const LogoLightIconImg = "https://yep2022.dev-enjoyworks.com/year_party_file/images/icons/logo_mobile.svg"

const Logo = ({ to }) => (
  <Link to={to} className="navbar-brand">
    <b className="logo-icon w-100">
      <img src={LogoLightMobile} alt="homepage" className="dark-logo" />
      <img src={LogoLightMobile} alt="homepage" className="light-logo w-100 h-100" />
      <img src={LogoLightMobile} alt="homepage" className="light-logo-mobile d-none w-100 h-100" />
    </b>
    {/*<span className="logo-text">
      <img src={LogoTextImg} alt="homepage" className="dark-logo" />
      <img src={LogoLightTextImg} className="light-logo" alt="homepage" />
    </span>*/}
  </Link>
)

export default Logo

Logo.defaultProps = {
  to: '/'
}

Logo.propTypes = {
  to: PropTypes.string,
}
